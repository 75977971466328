import axios from "axios";


const getAxiosConfig = () => {
	//  const token = getAuthToken();

	return {
		headers: {
			"Content-Type": "application/json",
			// "Authorization" : `token ${token}`
		}
	};
};


const getUrl = (url) => {
	let returnUrl = process.env.REACT_APP_PLATFORM_URL + url;
	return returnUrl;
};

const getBodyFormData = (obj) => {
	let formData = new FormData();
	Object.keys(obj).forEach(key => {
		formData.append(key, obj[key]);
	});
	return formData;
};

export const axiosGet = (url, token = false) => {
	
	let relativeUrl = getUrl(url);

	let options = getAxiosConfig();
	if (token) {
		options.headers["Authorization"] = `token ${localStorage.getItem('token')}`;
	}
	// delete options.headers["Authorization"];

	return axios.get(relativeUrl, options);
};


export const axiosPost = (url, data, withFormData = false, token = false) => {

	let relativeUrl = getUrl(url);

	let options = getAxiosConfig();

	if (withFormData) {
		data = getBodyFormData(data);
		delete options.headers["Content-Type"];
	}
	else {
		data = JSON.stringify(data);
	}

	if (token) {
		options.headers["Authorization"] = `token ${localStorage.getItem('token')}`;
	}

	return axios.post(relativeUrl, data, options);
};

export const axiosPut = (url, data, withFormData = false, token = false) => {

	let relativeUrl = getUrl(url);

	let options = getAxiosConfig();

	if (withFormData) {
		data = getBodyFormData(data);
		delete options.headers["Content-Type"];
	}
	else {
		data = JSON.stringify(data);
	}

	if (token) {
		options.headers["Authorization"] = `token ${localStorage.getItem('token')}`;
	}

	return axios.put(relativeUrl, data, options);


};

export const axiosDelete = (url, token = false) => {

	let relativeUrl = getUrl(url);

	let options = getAxiosConfig();

	if (token) {
		options.headers["Authorization"] = `token ${localStorage.getItem('token')}`;
	}

	return axios.delete(relativeUrl, options);


};