import React, {  Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Loadable from 'react-loadable';

// import '../../node_modules/font-awesome/scss/font-awesome.scss';

import Loader from './layout/Loader'
import Aux from "../hoc/_Aux";
import ScrollToTop from './layout/ScrollToTop';
// import SignUpIndex from '../Components/Authentication/SignUp';
import { useSelector } from 'react-redux';
import ErrorPage from '../Components/UI/404Page';
// import { TramOutlined } from '@material-ui/icons';
// import SignInIndex from '../Components/Authentication/SignIn';
// import ForgetPassword from '../Components/Authentication/SignIn/ForgetPassword';
// import ResetPassword from '../Components/Authentication/SignIn/ResetPassword';

const AdminLayout = Loadable({
    loader: () => import('./layout/AdminLayout'),
    loading: Loader
});

const SignUpIndex = React.lazy(()=>import('../Components/Authentication/SignUp'));
const SignInIndex = React.lazy(()=>import('../Components/Authentication/SignIn'));
const ResetPassword = React.lazy(()=>import('../Components/Authentication/SignIn/ResetPassword'));


// class App extends Component {
const App = () =>{   
    const isLogin = useSelector((state) => state.login.isLogin);
    // console.log("app login status ", isLogin);

    // const menu = routes.map((route, index) => {
    //     console.log(route);
    //     return (route.component) ? (
    //         <Route
    //             key={index}
    //             path={route.path}
    //             exact={route.exact}
    //             name={route.name}
    //             // element={route.component}
    //             render={props => (
    //                 <route.component {...props} />
    //             )} />
    //     ) : (null);
    // });
    
    return (
        <Aux>
            <ScrollToTop>
                <Suspense fallback={<Loader />}>
                    <Routes>
                        {/* {!isLogin && menu}  this condition is used for protecting authpages after signing in  */}
                        {!isLogin && <Route path='/auth/signup' element={<SignUpIndex />} />}
                        {!isLogin && <Route path='/auth/signin' element={<SignInIndex forgetPage={false}/>} />}
                        {!isLogin && <Route path='/auth/password/reset' element={<SignInIndex forgetPage={true}/>}/>}
                        {!isLogin && <Route path='/auth/password/reset/confirm/:id' element={<ResetPassword/>} />}
                        {!isLogin && <Route path='*' element={<Navigate to='/auth/signin' />} />}
                        {/* {!isLogin && <Route path='*' element={<ErrorPage/>}  />} */}

                    </Routes>
                    {isLogin && <AdminLayout/>}
                    {/* <AdminLayout/> */}
                </Suspense>
            </ScrollToTop>
        </Aux>
    );
}

export default App;
