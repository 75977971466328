import { configureStore } from '@reduxjs/toolkit';
import reducer from './reducer/reducer';
import reducerAuth from './reducer/reducer-auth';
import loginStatusSlice from './reducer/reducer-login';
import userDataSlice from './reducer/reducer-userData';
import { reducer as reducerEditor} from './reducer/reducer-editor'
import storyListSlice from './reducer/reducer-homeTable';
// import filterSlice from './reducer/filter-reducer';
const store = configureStore(
    {
        reducer: {
            legacy: reducer,
            auth: reducerAuth,
            login : loginStatusSlice.reducer,
            userInfo : userDataSlice.reducer,
            editor : reducerEditor,
            storyList : storyListSlice.reducer,
            // filters : filterSlice.reducer
        },
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: false,
            }),
    }
);

export default store;