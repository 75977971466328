import { createSlice } from "@reduxjs/toolkit";
import { getHomeEvents } from "../../api";

const storyListSlice = createSlice({
    name : 'storyTable',
    initialState : {
        stories : {}
    },
    reducers : {
        async fetch_story(state){
            const fetch = async() => {
                const data = await getHomeEvents();
                // console.log('redux-fetch',data);
                return data;
            }
            state.stories = await fetch();
            // console.log(state.stories);
        },
        delete(state,action){

        }
    }
});

export const storyListAction = storyListSlice.actions;

export default storyListSlice;