import { deleteStory, fetchHomeEvents, fetchUserProfileData, fetchReviewEvents, fetchAllUserData_ForAdminEvents,fetchFilterData, fetchHomeStoryEvents, deleteStoryEvent } from "./network";
import { updateContactDetail, updateOtherDetail, updatePersonalDetail, updateProfilePic } from "./updateProfileApi";



export async function getHomeEvents(offset, limit) {
    return await fetchHomeEvents(offset, limit);
}

export async function getFilterData(url) {
    return await fetchFilterData(url);
}

export async function deleteStoryFromTable(storyId) {
    return await deleteStory(storyId);
}

export async function getUserProfileData() {
    return await fetchUserProfileData();
}

export async function updatePersonalDetailApi(data) {
    return await updatePersonalDetail(data);
}

export async function updateContactDetailApi(data) {
    return await updateContactDetail(data);
}

export async function updateOtherDetailApi(data) {
    return await updateOtherDetail(data);
}

export async function updateProfilePicApi(picData) {
    return await updateProfilePic(picData);
}

export async function getHomeStoryEvents(page,filters,status){
    return await fetchHomeStoryEvents(page,filters,status);
}

export async function getReviewEvents(page, filters) {
    return await fetchReviewEvents(page, filters);
}

export async function getAllUserData() {
    return await fetchAllUserData_ForAdminEvents();
}

export async function deleteDraftStory(story_id){
    return await deleteStoryEvent(story_id);
}

