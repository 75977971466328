const apis ={
    image_upload_api:"/api/files/upload",
    event_upload_api:"/api/users/events",
    comments_api:"/api/rms/events/$[id]/comments",
    review_event_api:"/api/stories/events",
    comment_api:"/api/rms/comments",
    reply_api:"/api/rms/replies",
    category_api:"/api/stories/periods",
    approve_event:"/api/rms/events/$[id]/approve",
    resolved_comment:"/api/rms/comments/$[id]/resolve",
    discard_event:"/api/rms/events/$[id]/discard",
    event_api:"/api/rms/event-revisions",
    history_api:"/api/rms/event-revisions/$[id]/version-history",
    reviewers_data:"/api/rms/user-analysis/reviewer",
    author_data:"/api/rms/user-analysis/author",
    home_all_story:'/api/users/events',
    analytic_counts:'/api/users/events/count',
    final_check:"/api/rms/events/final",
    publish_api:"/api/rms/events/publish?event_id=$[id]",
    disapprove_event:"/api/rms/events/disapprove?event_id=$[id]&failed_reason=$[text]",
    inactive_requests:"/api/rms/inactive-requests",
    inactive_users:"/api/rms/inactive-requests/users",
    allow_user_request:"/api/rms/inactive-requests/$[id]/approve",
    discard_user_request:"/api/rms/inactive-requests/$[id]/reject",
    make_user_active:"/api/users/account/$[id]/activate/reviewer",
    get_reviewers:"/api/rms/event-revisions/$[id]/reviewers",
    delete_draft_story:'/api/users/events/',
    intern_api : '/api/users/account'
    
}

 export default apis