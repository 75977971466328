import axios from 'axios';
import apis from '../Components/Apis';
// var localStorageToken = localStorage.getItem('token');
// console.log(localStorageToken);
// var liveToken = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjQ2MDU4ODg3LCJleHAiOjE2NDg2NTA4ODd9.bxw18U5jRuMnte6WYgtLBeM5HOw-VO5pQhUUt79Wd-0'
// const userToken = 'token '+localStorageToken;
// console.log(userToken);

// export const  headerToken = () =>{
//     var localStorageToken = localStorage.getItem('token');
//     const userToken = 'token '+localStorageToken;
// console.log(userToken);

//     return userToken;
// }
// export const headers = { Authorization: headerToken() }

export async function fetchHomeEvents(offset, limit) {
    const url = `${`https://rufus-api.thisday.app/api/users/events?limit=${limit}&offset=${offset}`}`;
    // localStorage.setItem('url', url)
    try {
        const res = await axios.get(url
            , { headers: { Authorization: 'token ' + localStorage.getItem('token') } })
        // console.log("Events ==>> " + JSON.stringify(res.data));
        // console.log('first api hit',res);
        return res.data;
    }
    catch (error) {
        // console.log("Error fetching events ==>> " + error);
        return [];
    }
}

export async function fetchFilterData(url) {
    // localStorage.setItem('url',url);
    try {
        const res = await axios.get(url
            , { headers: { Authorization: 'token ' + localStorage.getItem('token') } })
        // console.log("Events ==>> " + JSON.stringify(res.data));
        // console.log('first api hit',res);
        return res.data;
    }
    catch (error) {
        // console.log("Error fetching events ==>> " + error);
        return [];
    }
}

export async function deleteStory(storyId) {
    try {
        const res = await axios.delete(`${process.env.REACT_APP_PLATFORM_URL}/api/users/events/${storyId}`,
            { headers: { Authorization: 'token ' + localStorage.getItem('token') } });
        return res.data;
    }
    catch (error) {
        // console.log("Error deleting story==>> " + error);
        return [];
    }
}


//
export async function fetchUserProfileData() {
    const userId = localStorage.getItem('userId');
    // console.log('userId====>>>>>',userId)

    try {
        const res = await axios.get(`${process.env.REACT_APP_PLATFORM_URL}/api/users/account/${userId}`,
            { headers: { Authorization: 'token ' + localStorage.getItem('token') } });
        // console.log(res);
        return res;
    }
    catch (error) {
        // console.log('Error in fetching User Profile Details');
    }
}

export async function fetchHomeStoryEvents(page,filters,status){

    try {
        let keys = Object.keys(filters),queryParams="";
        keys.forEach((key)=>{
            if(filters[key] && filters[key]!== -1 && filters[key]!== "-1"){
                // if(key === "status_days" && parseInt(filters[key])>8){
                //     queryParams+=`from_status_days=${8}&`
                // }else 
                if(Array.isArray(filters[key])){
                    filters[key].forEach((val)=>{
                        if(val!==-1){
                        queryParams+=`${key}=${val}&`
                        }
                    })
                }
                else{
                    queryParams+=`${key}=${filters[key]}&`
                }          
            }

        })
        queryParams +=  `offset=${(page - 1) * filters.limit}`;
        if(status!==''){
            queryParams+=`&status=${status}`
        }

        const res = await axios.get(`${process.env.REACT_APP_PLATFORM_URL}${apis.home_all_story}${queryParams ? `?${queryParams}` : ''}`
            , { headers: { Authorization: 'token ' + localStorage.getItem('token') } })
        // console.log("Events ==>> " + JSON.stringify(res.data));
        // console.log('first api hit',res);
        // console.log(res);
        return res.data;
    }
    catch (error) {
        // console.log("Error fetching events ==>> " + error);
        return [];
    }
}

export async function fetchReviewEvents(page, filters) {


    try {

        let keys = Object.keys(filters),queryParams="";
        keys.forEach((key)=>{
            if(filters[key] && filters[key]!== -1 && filters[key]!== "-1"){
                if(key === "status_days" && parseInt(filters[key])>8){
                    queryParams+=`from_status_days=${8}&`
                }else if(Array.isArray(filters[key])){
                    filters[key].forEach((val)=>{
                        if(val!==-1){
                        queryParams+=`${key}=${val}&`
                        }
                    })
                }
                else{
                    queryParams+=`${key}=${filters[key]}&`
                }          
            }

        })
        queryParams += `offset=${(page - 1) * filters.limit}`;


        const res = await axios.get(`${process.env.REACT_APP_PLATFORM_URL}/api/rms/events${queryParams ? `?${queryParams}` : ''}`
            , { headers: { Authorization: 'token ' + localStorage.getItem('token') } })
        // console.log("Events ==>> " + JSON.stringify(res.data));
        // console.log('first api hit',res);
        return res.data;
    }
    catch (error) {
        // console.log("Error fetching events ==>> " + error);
        return [];
    }
}

export async function fetchAllUserData_ForAdminEvents() {


    try {
        const res = await axios.get(`${process.env.REACT_APP_PLATFORM_URL}/api/users/events?limit=5000&offset=0`
            , { headers: { Authorization: 'token ' + localStorage.getItem('token') } })
        // console.log("Events ==>> " + JSON.stringify(res.data));
        // console.log('first api hit',res);
        return res.data;
    }
    catch (error) {
        // console.log("Error fetching events ==>> " + error);
        return [];
    }
} 

export async function deleteStoryEvent (story_id){
    try {
        const res = await axios.delete(`${process.env.REACT_APP_PLATFORM_URL}${apis.delete_draft_story}${story_id}`,
        { headers: { Authorization: 'token ' + localStorage.getItem('token') } });

        return true;
    }
    catch (error){
        console.log('error in deleting story');
        return false;
    }
}
